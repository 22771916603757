<template>
  <div
    class="tc-system_setup"
    v-loading="loading"
    :element-loading-text="loadingText"
  >
    <el-form ref="form" :model="form" label-width="180px" size="mini">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="基本配置项" name="1">
          <el-col :span="24">
            <el-form-item label="单位名称：">
              <el-input v-model="form.unit_name"></el-input>
            </el-form-item>
            <el-form-item label="系统名称：">
              <el-input v-model="form.systematic_name"></el-input>
            </el-form-item>
            <el-form-item label="联系电话：">
              <el-input
                v-model="form.contact_number"
                onkeyup="value=value.replace(/[^\d]/g,'') "
              ></el-input>
            </el-form-item>
            <el-form-item label="LOGO：">
              <el-upload
                class="avatar-uploader"
                :action="$baseurl + '/file_upload'"
                :show-file-list="false"
                :on-success="fileUploadApi"
                :before-upload="beforeAvatarUpload"
                :data="fileData"
                :with-credentials="true"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="部门分级：">
              <el-radio-group v-model="form.department_classifiy">
                <el-radio label="0">标准</el-radio>
                <el-radio label="1">多级</el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- <el-form-item label="测评模式：">
              <el-radio-group v-model="form.assessment_model">
                <el-radio label="0">集成模式</el-radio>
                <el-radio label="1">分流模式</el-radio>
              </el-radio-group>
            </el-form-item>-->
            <el-form-item label="是否允许续评：">
              <el-radio-group v-model="form.continue_test">
                <el-radio label="1">允许</el-radio>
                <el-radio label="0">禁止</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="是否允许重做：">
              <el-radio-group v-model="form.allow_redo">
                <el-radio label="1">允许</el-radio>
                <el-radio label="0">禁止</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="报告显示更多用户信息：">
              <el-radio-group v-model="form.report_show_more">
                <el-radio label="1">开启</el-radio>
                <el-radio label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="开启测试同意书：">
              <el-radio-group v-model="form.statement">
                <el-radio label="1">开启</el-radio>
                <el-radio label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="用户切换认证：">
              <el-radio-group v-model="form.switchover">
                <el-radio label="1">开启</el-radio>
                <el-radio label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="是否可打印未审核报告：">
              <el-radio-group v-model="form.print_no_inspect">
                <el-radio label="1">开启</el-radio>
                <el-radio label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- <el-form-item label="用户注册：">
              <el-radio-group v-model="form.user_register">
                <el-radio label="1">标准注册</el-radio>
                <el-radio label="2">二维码注册</el-radio>
                <el-radio label="3">二维码登陆</el-radio>
                <el-radio label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <!-- <el-form-item label="允许上传文件资源类型：">
              <el-checkbox-group v-model="form.allow_res_extension">
                <el-checkbox label="png" name="type"></el-checkbox>
                <el-checkbox label="jpg" name="type"></el-checkbox>
                <el-checkbox label="xml" name="type"></el-checkbox>
                <el-checkbox label="jpeg" name="type"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>-->
            <el-form-item label="允许选择的部门">
              <el-checkbox-group v-model="form.allow_department_id">
                <el-checkbox
                  :label="item.id + ''"
                  name="type"
                  v-for="item in departmentList"
                  :key="item.id"
                  >{{ item.name }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
            <el-row>
              <el-col :span="8">
                <el-form-item label="全局缓存时间">
                  <el-input
                    v-model="form.cache_time"
                    onkeyup="value=value.replace(/[^\d]/g,'') "
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="注册后默认使用的量表：">
              <p class="extend" @click="mesHide()">
                <span v-if="mes">关闭</span>
                <span v-if="!mes">打开</span>量表权限设置
              </p>
              <el-collapse-transition>
                <el-tabs v-model="scale" v-show="mes">
                  <el-tab-pane label="所有可用量表" name="first">
                    <div class="query">
                      <el-form :inline="true" :model="form" size="mini">
                        <el-form-item>
                          <el-input
                            v-model="form.scaleName"
                            placeholder="量表名称"
                          ></el-input>
                        </el-form-item>
                        <el-form-item>
                          <el-button type="primary" @click="queryScale()"
                            >量表查询</el-button
                          >
                        </el-form-item>
                        <el-form-item>
                          <el-button type="primary" @click="checkAll(1)"
                            >全选或取消所有量表</el-button
                          >
                        </el-form-item>
                        <el-form-item>
                          <el-button type="primary" @click="checkAll(2)"
                            >全选或取消测评人查看结果</el-button
                          >
                        </el-form-item>
                      </el-form>
                    </div>
                    <!-- 所有量表 -->
                    <div class="table" @change="handleCheckAllChange">
                      <table>
                        <tr>
                          <th>量表名称</th>
                          <th>使用该量表</th>
                          <th>允许测评人看测评结果</th>
                          <th>测评次数</th>
                        </tr>
                        <!-- ----------------------- -->
                        <template v-for="(item, key) in scaleData">
                          <tr
                            class="title"
                            :key="key"
                            v-if="item.status_measure.length > 0"
                          >
                            <td colspan="4">{{ item.classify_name }}</td>
                          </tr>
                          <tr
                            v-for="item2 in item.status_measure"
                            :key="item2.classify_name"
                          >
                            <td>{{ item2.measure_title }}</td>
                            <td>
                              <el-checkbox-group v-model="checkList">
                                <el-checkbox
                                  :label="item2.id"
                                  name="a"
                                ></el-checkbox>
                              </el-checkbox-group>
                            </td>
                            <td>
                              <el-checkbox-group v-model="checkList2">
                                <el-checkbox
                                  :label="item2.id"
                                  name="b"
                                ></el-checkbox>
                              </el-checkbox-group>
                            </td>
                            <td>
                              <el-input
                                v-model="item2.max_num"
                                placeholder=""
                                size="mini"
                                :name="item2.id"
                                maxlength="2"
                                onkeyup="value=value.replace(/[^\d]/g,'')"
                              ></el-input>
                            </td>
                          </tr>
                        </template>
                      </table>
                    </div>
                    <el-col :span="10">
                      <!-- <el-form-item
                        label="每份量表测评次数"
                        label-width="150px"
                      >
                        <el-input
                          v-model="form.test_times"
                          onkeyup="value=value.replace(/[^\d]/g,'')"
                          maxlength="3"
                        ></el-input>
                      </el-form-item> -->
                      <el-form-item
                        label="已测量表是否显示"
                        label-width="150px"
                      >
                        <el-radio-group v-model="form.show_tested_measure">
                          <el-radio label="0">否</el-radio>
                          <el-radio label="1">是</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-tab-pane>
                  <el-tab-pane label="量表组合包" name="second">
                    <div class="table">
                      <table @change="handleCheckAllChange2">
                        <tr>
                          <th>分组量表名称</th>
                          <th>量表数量</th>
                          <!-- <th>次数限制</th> -->
                          <th>应用该分组</th>
                        </tr>
                        <!-- ----------------------- -->
                        <tr v-for="(item, key) in scaleData2.data" :key="key">
                          <td>{{ item.group_name }}</td>
                          <td>{{ item.measure_count }}</td>
                          <!-- <td>{{ item.test_num }}</td> -->

                          <td>
                            <el-checkbox-group v-model="checkList3">
                              <el-checkbox :label="item.id"></el-checkbox>
                            </el-checkbox-group>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </el-collapse-transition>
            </el-form-item>
            <el-form-item label="注册后默认使用的问卷：">
              <!-- 问卷权限 -->
              <el-col class="msqtable">
                <p class="extend" @click="msqHide()">
                  <span v-if="msq">关闭</span>
                  <span v-if="!msq">打开</span>问卷权限设置
                </p>
                <el-collapse-transition>
                  <div class="table" @change="handleCheckAllChange3" v-if="msq">
                    <table>
                      <tr>
                        <th>问卷(试卷)名称</th>
                        <th>使用该问卷</th>
                        <!-- <th>允许测评人看到得分</th> -->
                      </tr>
                      <!-- ----------------------- -->
                      <tr class="title">
                        <td colspan="3">选择要授权的问卷</td>
                      </tr>
                      <template v-for="item in questionnaireData">
                        <tr
                          class="title"
                          :key="item.id"
                          v-if="item[0].status_questionnaires.length != 0"
                        >
                          <td colspan="3">{{ item[0].type_name }}</td>
                        </tr>
                        <tr
                          v-for="item2 in item[0].status_questionnaires"
                          :key="item2.id"
                        >
                          <td>{{ item2.title }}</td>
                          <td>
                            <el-checkbox-group v-model="checkList11">
                              <el-checkbox
                                :label="item2.id"
                                name="a"
                              ></el-checkbox>
                            </el-checkbox-group>
                          </td>
                          <!-- <td>
                            <el-checkbox-group v-model="checkList22">
                              <el-checkbox
                                :label="item2.id"
                                name="b"
                              ></el-checkbox>
                            </el-checkbox-group>
                          </td> -->
                        </tr>
                      </template>
                    </table>
                  </div>
                </el-collapse-transition>
              </el-col>
            </el-form-item>
          </el-col>
        </el-collapse-item>
        <el-collapse-item title="七牛云配置项" name="2">
          <el-col :span="8">
            <el-form-item label="全局资源读取存储方式">
              <el-radio-group v-model="form.res_route">
                <el-radio label="local_url">本地(local_url)</el-radio>
                <el-radio label="api_url">云(api_url)</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="七牛云对外链接">
              <el-input v-model="form.qnyUrl"></el-input>
            </el-form-item>
            <el-form-item label="七牛云AK密钥">
              <el-input v-model="form.accessKey"></el-input>
            </el-form-item>
            <el-form-item label="七牛云SK密钥">
              <el-input v-model="form.secretKey"></el-input>
            </el-form-item>
            <el-form-item label="七牛云空间名称">
              <el-input v-model="form.bucket"></el-input>
            </el-form-item>
          </el-col>
        </el-collapse-item>
        <el-collapse-item title="报告配置项" name="3">
          <el-form-item label="报告样式：">
            <el-radio-group v-model="form.report_style">
              <el-radio label="0">指导模式</el-radio>
              <el-radio label="1">诊断模式</el-radio>
              <el-radio label="2">简易模式</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="报告导出格式：">
            <el-radio-group v-model="form.report_export_format">
              <el-radio label="0">Word格式</el-radio>
              <el-radio label="1">兼容Wps格式</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="报告显示更多用户信息：">
            <el-radio-group v-model="form.report_show_more">
              <el-radio label="1">开启</el-radio>
              <el-radio label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="报告字体大小：">
            <el-radio-group v-model="form.report_font_size">
              <el-radio label="10">10号</el-radio>
              <el-radio label="11">11号</el-radio>
              <el-radio label="12">12号</el-radio>
              <el-radio label="14">14号</el-radio>
              <el-radio label="16">16号</el-radio>
              <el-radio label="18">18号</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="报告打印宽度：">
            <el-radio-group v-model="form.report_width">
              <el-radio label="1">A1</el-radio>
              <el-radio label="2">A2</el-radio>
              <el-radio label="3">A3</el-radio>
              <el-radio label="4">A4</el-radio>
              <el-radio label="5">A5</el-radio>
              <el-radio label="6">A6</el-radio>
              <el-radio label="7">A7</el-radio>
              <el-radio label="8">A8</el-radio>
              <el-radio label="9">A9</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item title="用户报告配置项" name="4">
          <el-form-item label="基本信息：">
            <el-radio-group v-model="form.user_scope_basic">
              <el-radio label="1">显示</el-radio>
              <el-radio label="0">隐藏</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="图形：">
            <el-radio-group v-model="form.user_scope_graph">
              <el-radio label="1">显示</el-radio>
              <el-radio label="0">隐藏</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="因子表：">
            <el-radio-group v-model="form.user_scope_factor">
              <el-radio label="1">显示</el-radio>
              <el-radio label="0">隐藏</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="分数级别：">
            <el-radio-group v-model="form.user_scope_score">
              <el-radio label="1">显示</el-radio>
              <el-radio label="0">隐藏</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="因子说明：">
            <el-radio-group v-model="form.user_scope_explain">
              <el-radio label="1">显示</el-radio>
              <el-radio label="0">隐藏</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="指导建议：">
            <el-radio-group v-model="form.user_scope_suggest">
              <el-radio label="1">显示</el-radio>
              <el-radio label="0">隐藏</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="测试师：">
            <el-radio-group v-model="form.user_scope_assessor">
              <el-radio label="1">显示</el-radio>
              <el-radio label="0">隐藏</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>
    </el-form>
    <el-row type="flex" justify="center" style="margin-top: 30px">
      <el-button type="primary" @click="save">保存设置</el-button>
    </el-row>
  </div>
</template>
<script>
import axios from "axios";
import {
  loadDepartmentData,
  selectConfig,
  updateConfig,
  getListConfig,
  getAllQuestionnaires,
} from "@/api/comm.js";
import { getMeasureGroupList, getMeasureByClassify } from "@/api/evaluate.js";
import { log } from "util";
export default {
  inject: ["reload"],
  data() {
    return {
      loadingText: "页面加载中",
      loading: true,
      departmentList: [],
      form: {
        allow_res_extension: [],
        allow_department_id: [],
        measure: [],
        cache_time: "",
        res_route: "",
        qnyUrl: "",
        accessKey: "",
        secretKey: "",
        bucket: "",
        allow_res_extension: "",
        unit_name: "",
        department_classifiy: "",
        // assessment_model: "",
        continue_test: "",
        allow_redo: "",
        report_style: "",
        report_export_format: "",
        report_font_size: "",
        report_width: "",
        report_show_more: "",
        statement: "",
        switchover: "",
        print_no_inspect: "",
        // user_register: "",
        test_times: "",
        show_tested_measure: "",
        measure_group: [],
        questionnaire: [],
        statementmain: "",
        systematic_name: "",
        contact_number: "",
        logo: "",
        user_scope_assessor: "",
        user_scope_basic: "",
        user_scope_explain: "",
        user_scope_factor: "",
        user_scope_graph: "",
        user_scope_score: "",
        user_scope_suggest: "",
      },
      fileList: [],
      activeNames: ["1"],
      // ---------量表数据--------------
      // 量表tab栏
      scale: "first",
      // 所有量表-是否使用
      checkList: [],
      // 所有量表-是否有查看权限
      checkList2: [],
      // 所有量表数据渲染
      scaleData: [],
      // 所有量表数据存储
      scaleData1: [],
      // 量表组合包-是否使用
      checkList3: [],
      //量表组合包数据
      scaleData2: [],
      // 所有量表-全选控制
      checkAll1: 1,
      checkAll2: 1,
      // ------------------调查问卷---------------
      checkList11: [],
      checkList22: [],
      scaleData11: [],
      questionnaireData: [],
      msq: false,
      mes: false,
      fileData: {
        type: 6,
        attribution_id: 1, // 当前 用户的id
      },
      imageUrl: "",
    };
  },
  created() {
    // 量表加载
    this.loadScaleData();
    this.loadScaleData2();
    this.loadQuestionnaires();
    // 获取所有部门
    loadDepartmentData().then((res) => {
      this.departmentList = res.data.data;
    });
    // 获取页面参数
    this.ini();
  },
  methods: {
    // ----------文化上传钩子------------
    // 文件上传成功的狗子
    fileUploadApi(response, file) {
      this.form.logo = response.data.id;
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    // 图片上次校验
    beforeAvatarUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "png" ||
        testmsg === "PNG" ||
        testmsg === "bpm" ||
        testmsg === "BPM";
      const isLt50M = file.size / 1024 / 1024 < 2;
      if (!extension) {
        this.$message({
          message: "上传图片只能是jpg / png / bpm格式!",
          type: "error",
        });
        return false;
      }
      //console.log(file);
      if (!isLt50M) {
        this.$message({
          message: "上传文件大小不能超过 2MB!",
          type: "error",
        });
        return false;
      }
      return extension || isLt50M;
    },
    // ------------------量表-------------------------

    //判断是否选择了量表，是否有权利选择结果,处理数据到  form.measure 中
    handleCheckAllChange(val) {
      var a = parseInt(val.target.value);
      //console.log(a);
      if (val.target.name === "a") {
        var obj = { measure_id: a, show_result: 0 };
        var tmp = this.form.measure.some(function (item) {
          return item.measure_id == a;
        });
        if (tmp) {
          this.form.measure = this.form.measure.filter((v) => {
            return v.measure_id != a;
          });
        } else {
          this.form.measure.push(obj);
        }
      } else if (val.target.name === "b") {
        var p = this.checkList.indexOf(a);
        var index = this.form.measure.findIndex((item) => {
          return item.measure_id == a;
        });
        if (this.form.measure[index] && index != -1) {
          if (this.form.measure[index].show_result == 0) {
            this.form.measure[index].show_result = 1;
          } else {
            this.form.measure[index].show_result = 0;
          }
        }

        if (p === -1) {
          this.$message.error("请先选择该表单后，在选择此选项");
          this.checkList2 = this.checkList2.filter((v) => {
            return v != val.target.value;
          });
          return;
        }
      } else {
        let st = true;
        this.form.measure.forEach((v) => {
          if (v.measure_id == val.target.name) {
            v.max_num = val.target.value || 0;
            st = false;
          }
        });
        if (st) {
          this.$message({
            message: "警告,量表未勾选",
            type: "warning",
          });
          val.target.value = "";
        }
      }
    },
    // 全选、取消
    checkAll(res) {
      // 量表
      if (res === 1) {
        if (this.checkAll1 === 1) {
          this.scaleData.forEach((v) => {
            v.status_measure.forEach((v) => {
              this.checkList.push(v.id);
              var obj = { measure_id: v.id, show_result: 1 };
              this.form.measure.push(obj);
            });
          });
          this.checkAll1 = 0;
        } else {
          this.checkList = [];
          this.checkList2 = [];
          this.form.measure = [];
          this.checkAll1 = 1;
        }
      } else {
        if (this.checkAll2 === 1) {
          //1-先判断是否有选择的量表
          if (this.checkList <= 0) {
            this.$message.error("请选择量表后，再全选");
            return;
          }
          //2-把  checkList 的值 给 checkList2
          let a = this.checkList;
          this.checkList2 = a;
          //3-通过  checkList2 的值 依次 寻找measure 中的 show_result 改成 1
          this.checkList2.forEach((v, k) => {
            for (var i = 0; i < this.form.measure.length; i++) {
              if (this.form.measure[i].measure_id === v) {
                this.form.measure[i].show_result = 1;
              }
            }
          });
          this.checkAll2 = 0;
        } else {
          //1-先判断是否有选择的量表
          if (this.checkList <= 0) {
            this.$message.error("请选择量表后，再全选");
            return;
          }
          //3-通过  checkList2 的值 依次 寻找measure 中的 show_result 改成 1
          this.checkList2.forEach((v, k) => {
            for (var i = 0; i < this.form.measure.length; i++) {
              if (this.form.measure[i].measure_id === v) {
                this.form.measure[i].show_result = 0;
              }
            }
          });
          this.checkList2 = [];
          this.checkAll2 = 1;
        }
      }
    },
    // 组合量表
    handleCheckAllChange2(val) {
      // 清除所有;
      // this.checkList3 = [];
      // console.log(val.target.checked);
      // this.checkList3.push(parseInt(val.target.value));
      this.checkList3 = [];
      if (val.target.checked) {
        this.checkList3.push(parseInt(val.target.value));
      }
    },
    // 量表数据请求
    async loadScaleData() {
      // var res = await axios.get("/all_measure_classify");
      var res = await getMeasureByClassify();
      if (res.code === 400200) {
        this.scaleData = res.data;
        this.scaleData1 = res.data;
      } else {
        return;
      }
    },
    //组合量表包请求
    async loadScaleData2() {
      // var res = await axios.get("/list_measure_group");
      var res = await getMeasureGroupList();
      this.scaleData2 = res.data;
      //console.log("组合", this.scaleData2);
    },
    // 打开关闭问卷权限
    msqHide() {
      if (!this.msq) {
        this.msq = true;
      } else {
        this.msq = false;
      }
    },
    mesHide() {
      if (!this.mes) {
        this.mes = true;
      } else {
        this.mes = false;
      }
    },
    // 调查问卷 勾选、数据处理
    handleCheckAllChange3(val) {
      var a = parseInt(val.target.value);
      if (val.target.name == "a") {
        var obj = { questionnaire_id: a, show_result: "" || 0 };
        var tmp = this.form.questionnaire.some(function (item) {
          return item.questionnaire_id == a;
        });
        if (tmp) {
          this.form.questionnaire = this.form.questionnaire.filter((v) => {
            return v.questionnaire_id != val.target.value;
          });
        } else {
          this.form.questionnaire.push(obj);
        }
      }
      if (val.target.name == "b") {
        var p = this.checkList11.indexOf(a);
        var index = this.form.questionnaire.findIndex((item) => {
          return item.questionnaire_id == a;
        });
        if (this.form.questionnaire[index] && index != -1) {
          if (this.form.questionnaire[index].show_result === 0) {
            this.form.questionnaire[index].show_result = 1;
          } else {
            this.form.questionnaire[index].show_result = 0;
          }
        }

        if (p === -1) {
          this.$message.error("请先选择该表单后，在选择此选项");
          this.checkList22 = this.checkList22.filter((v) => {
            return v != val.target.value;
          });
          return;
        }
      }
    },
    // 保存
    save() {
      this.loadingText = "正在更新系统配置";
      this.loading = true;
      // delete this.form.config_type;
      if (this.scale != "second") {
        delete this.form.measure_group;
      } else {
        if (this.checkList3.length > 0) {
          this.form.measure_group = this.checkList3[0];
        } else {
          this.form.measure_group = "";
        }
        delete this.form.measure;
      }

      //  return;
      updateConfig(this.form).then((res) => {
        if (res.code === 400200) {
          this.$message({
            message: "恭喜你，修改成功",
            type: "success",
          });
          // 获取全集参数修改 网站tilte
          getListConfig().then((res) => {
            if (res.code == 400200) {
              this.loading = false;
              document.title = res.data.systematic_name;
              localStorage.setItem("config", JSON.stringify(res.data));
            }
          });
          // this.reload();
          if (this.scale != "second") {
            this.checkList3 = [];
          } else {
            this.checkList = [];
            this.checkList2 = [];
          }
          this.ini();
        }
      });
    },
    // 页面初始化
    ini() {
      selectConfig({ config_type: "system_config,user_config_scope" }).then(
        (res) => {
          if (res.code == 400200) {
            //console.log(res);
            let {
              measure_preset,
              measure_group,
              logo_url,
              questionnaire_preset,
            } = res.data;
            this.imageUrl = logo_url;
            this.form = res.data;
            if (measure_group != "") {
              this.scale = "second";
            } else {
              this.scale = "first";
            }
            this.checkList3.push(parseInt(measure_group));

            measure_preset.forEach((v, k) => {
              v.measure_id = parseInt(v.measure_id);
              v.show_result = parseInt(v.show_result);
              this.checkList.push(v.measure_id);
              if (v.show_result === 1) {
                this.checkList2.push(v.measure_id);
              }
            });
            // 次数
            this.scaleData.forEach((v) => {
              if (v.status_measure.length > 0) {
                v.status_measure.forEach((v2) => {
                  measure_preset.forEach((v3) => {
                    if (v2.id == v3.measure_id) {
                      this.$set(v2, "max_num", v3.max_num || 0);
                    }
                  });
                });
              }
            });

            questionnaire_preset.forEach((v, k) => {
              v.questionnaire_id = parseInt(v.questionnaire_id);
              v.show_result = parseInt(v.show_result);
              this.checkList11.push(v.questionnaire_id);
              if (v.show_result === 1) {
                this.checkList22.push(v.questionnaire_id);
              }
            });

            this.form.questionnaire = questionnaire_preset;
            this.form.measure = measure_preset;
            this.loading = false;
          }
        }
      );
    },
    // 问卷数据
    loadQuestionnaires() {
      getAllQuestionnaires().then((res) => {
        this.questionnaireData = res.data;
      });
    },
  },
};
</script>
<style lang="less">
.tc-system_setup {
  // 抽屉
  .el-collapse {
    border: 0px;
    .el-collapse-item__header {
      // border-top: 1px rgb(38, 103, 114) solid;
      background: transparent;
      font-weight: 700;
      font-size: 16px;
    }

    .el-collapse-item__wrap {
      background: transparent;
    }
    .el-form-item {
      margin-bottom: 8px;
    }
    .el-button--small {
      padding: 6px 24px;
    }
    // 头像
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 250px;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }
    .avatar {
      width: 250px;
      height: 100px;
      display: block;
    }
    .avatar-uploader {
      // display: flex;
      // justify-content: center;
    }
  }
  // 量表
  .table {
    width: 950px;
    height: 300px;
    overflow: auto;
    border: 1px #cccccc solid;
    margin-bottom: 20px;
    .el-input__inner {
      width: 120px;
      padding-right: 20px;
    }

    table {
      width: 945px;
      text-align: center;
      overflow: auto;
      border-collapse: collapse;
      th {
        border-bottom: 1px solid #cccccc;
        border-right: 1px solid #cccccc;
      }
      tr,
      th {
        height: 20px;
        padding: 0;
        font-weight: 300;
        font-size: 14px;
      }
      tr {
        td {
          padding: 2px 5px;
          &:nth-child(1) {
            text-align: left;
          }
          .el-input-number {
            .el-input__inner {
              padding: 0;
            }
          }
        }
        &:hover {
          background: #b4e7e9;
        }
      }
      th {
        height: 30px;
        font-size: 15px;
        background: rgb(224, 245, 250);
      }
      .title {
        background: rgb(224, 245, 250);
        text-align: left;
        font-weight: 700;
        font-size: 15px;
      }
      .el-checkbox__label {
        display: none;
      }
    }
  }
  .query {
    p {
      line-height: 30px;
      font-size: 14px;
      color: #606266;
      margin-bottom: 10px;
      padding-left: 10px;
    }
  }
  .extend {
    color: rgb(12, 118, 172);
    cursor: pointer;
    text-decoration: underline;
  }
  // 分栏
  .el-tabs__item {
    height: 0px;
    line-height: 0px;
  }
}
</style>